<template>
  <section class="my-0 pa-0">
    <v-card class="mx-10 px-10">
      <div class="card-header-padding pt-10 px-3">
        <v-row>
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Үнэлгээ 1 ({{ userData.school.currentYear }})
              </h5>
              <!-- <p v-if="students2">
                <span v-if="eeljitLessons">
                  Ээлжит хичээлийн тоо: 
                  <span class="blue--text font-weight-bold">{{
                    eeljitLessons.length
                  }}</span></span
                >
              </p> -->
            </div>
          </div>
          <v-spacer></v-spacer>
          <!-- <v-col cols="2">
            <v-checkbox
              :disabled="disableLeaderboard"
              v-model="showLeaderBoard"
              :label="showLeaderBoard ? 'Ахисан' : 'Энгийн'"
            ></v-checkbox>
          </v-col> -->
          <!-- <v-col cols="3">
            <v-select
              style="background-color: yellow"
              :items="[1, 2, 3]"
              v-model="selectedSemester"
              label="Улирал"
            >
            </v-select>
          </v-col> -->
        </v-row>
      </div>
      <div></div>
      <div class="table-container" v-if="loading == false">
        <h4 class="text-typo mb-4" v-if="zaavalLessons">
          Заавал судлаx xичээлүүд (<span class="blue--text">{{
            zaavalLessons.length
          }}</span
          >)
        </h4>

        <table v-if="zaavalLessons && zaavalLessons.length > 0">
          <thead>
            <tr>
              <th class="text-center" style="width: 1%">No.</th>
              <th>Xичээлийн нэр</th>

              <th>Багшийн нэр</th>
              <th
                @click="_showLessonInfo(eLesson)"
                style="max-width: 10%"
                class="text-center"
              >
                Төрөл
              </th>
              <th
                class="text-center"
                style="width: 10%"
                v-for="(sem, semIndex) in $store.state.yearSemesters"
                :key="sem + 'session' + semIndex"
              >
                {{ sem }}-р улирал
              </th>

              <th class="text-center" style="width: 5%">Дундаж</th>

              <!-- <th
                v-for="(scoreLevel, sindex) in scoreLevels"
                :key="'scoreLevel-header-' + sindex"
              >
                {{ scoreLevel.name }}
              </th> -->
            </tr>
          </thead>
          <tbody v-if="zaavalLessons && zaavalLessons.length > 0">
            <tr v-for="(eLesson, sIndex) in zaavalLessons" :key="eLesson.id">
              <td class="fixed-column text-center" @click="print(eLesson)">
                {{ sIndex + 1 }}
              </td>
              <td class="fixed-column" style="width: 25%">
                {{ eLesson.courseInfo.SUBJECT_AREA_NAME }} -
                {{ eLesson.participated }}
              </td>
              <td style="width: 25%">
                <template>
                  <small
                    class="blue--text"
                    v-for="(teacher, teacherIndex) in eLesson.byTeachers"
                    :key="teacherIndex"
                    ><span v-if="teacherIndex > 0">, </span
                    >{{ teacher.teacherDisplayName }}</small
                  >
                </template>
              </td>
              <td style="width: 15%" class="text-center">
                <small>{{ eLesson.courseInfo.ENROLLMENT_CATEGORY_NAME }}</small>
              </td>
              <td
                class="text-center"
                :style="
                  'background-color:' +
                  eLesson.backgroundColor +
                  '!important;' +
                  'color:' +
                  eLesson.textColor
                "
                v-for="(sem, semIndex) in $store.state.yearSemesters"
                :key="sem + 'session-scores' + semIndex"
              >
                {{ _getScores(eLesson, sem) }}
              </td>

              <td class="text-center blue--text">
                <span v-if="eLesson.allScores && eLesson.allScores.length > 0">
                  {{ _getAverage(eLesson) }}</span
                >
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>
        <v-progress-linear
          v-else
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
      </div>
      <div class="table-container pb-10">
        <h4 v-if="selectiveLessons" class="text-typo mt-10 mb-4">
          <span class="blue--text">Сонгон</span> судлаx xичээлүүд (<span
            class="blue--text"
            >{{ selectiveLessons.length }}</span
          >)
        </h4>
        <table v-if="selectiveLessons && selectiveLessons.length > 0">
          <thead>
            <tr>
              <th class="text-center" style="width: 1%">No.</th>
              <th>Xичээлийн нэр</th>
              <th>Багшийн нэр</th>
              <th
                @click="_showLessonInfo(eLesson)"
                style="max-width: 10%"
                class="text-center"
              >
                Төрөл
              </th>
              <th class="text-center" style="width: 10%">Дүн (%)</th>
              <th class="text-center" style="width: 5%">Түвшин</th>

              <!-- <th
                v-for="(scoreLevel, sindex) in scoreLevels"
                :key="'scoreLevel-header-' + sindex"
              >
                {{ scoreLevel.name }}
              </th> -->
            </tr>
          </thead>
          <tbody v-if="selectiveLessons && selectiveLessons.length > 0">
            <tr v-for="(eLesson, sIndex) in selectiveLessons" :key="eLesson.id">
              <td class="fixed-column text-center">{{ sIndex + 1 }}</td>
              <td class="fixed-column" style="width: 25%">
                {{ eLesson.courseInfo.SUBJECT_AREA_NAME }}
              </td>
              <td style="width: 25%">
                <template>
                  <small
                    class="blue--text"
                    v-for="(teacher, teacherIndex) in eLesson.byTeachers"
                    :key="teacherIndex"
                    ><span v-if="teacherIndex > 0">, </span
                    >{{ teacher.teacherDisplayName }}</small
                  >
                </template>
              </td>
              <td style="width: 15%" class="text-center">
                <small>{{ eLesson.courseInfo.ENROLLMENT_CATEGORY_NAME }}</small>
              </td>
              <td
                class="text-center"
                :style="
                  'background-color:' +
                  eLesson.backgroundColor +
                  '!important;' +
                  'color:' +
                  eLesson.textColor
                "
              >
                {{ eLesson.allScores.map((xx) => xx.score).join(", ") }}
              </td>
              <td class="text-center blue--text">
                <span v-if="eLesson.allScores && eLesson.allScores.length > 0">
                  {{ _getAverage(eLesson) }}</span
                >
                <span v-else>-</span>
              </td>
            </tr>
          </tbody>
        </table>
        <v-progress-linear
          v-else-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <p v-else class="red--text">Сонгон судлаx xичээл байxгүй байна!</p>
      </div>
    </v-card>
  </section>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";

export default {
  components: {},
  data: () => ({
    selectedSemester: null,
    eeljitLessons: null,
    selectedTab: 0,
    selectedStudent: null,

    currentClassGroup: null,
    loading: false,
    myClass: null,
    myDepartment: null,
    selectedDepartment: null,
    selectedClassGroup: null,
    departments: null,
    selected: [],
    excelLoading: false,
    myStudents: null,
    scoreLevels: [
      {
        name: "I",
        level: 1,
      },
      {
        name: "II",
        level: 2,
      },
      {
        name: "III",
        level: 3,
      },
      {
        name: "IV",
        level: 4,
      },
      {
        name: "V",
        level: 5,
      },
      {
        name: "VI",
        level: 6,
      },
      {
        name: "VII",
        level: 7,
      },
      {
        name: "VIII",
        level: 8,
      },
    ],
    finishedLoading: false,
    alreadySetup: false,
    selectedHalfYear: null,
    preText: null,
    showingOnlyApproved: false,
    showLeaderBoard: false,
    disableLeaderboard: true,
  }),
  props: {
    zSchool: {
      type: Object,
    },
    zClassGroup: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    zaavalLessons() {
      var list = [];
      if (this.eeljitLessons) {
        for (const el of this.eeljitLessons) {
          if (el.courseInfo.ENROLLMENT_CATEGORY != "ELECTIVE") {
            if (el.esisLessonType.esisLessonTypeId == 1) list.push(el);
            else {
              if (el.participated) list.push(el);
            }
          }
        }
      }
      return list;
    },
    selectiveLessons() {
      var list = [];
      if (this.eeljitLessons) {
        for (const el of this.eeljitLessons) {
          if (el.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE") {
            if (el.esisLessonType.esisLessonTypeId == 1) list.push(el);
            else {
              if (el.participated) list.push(el);
            }
          }
        }
      }
      return list;
    },
    students2() {
      var list = [];
      if (this.myStudents != null) {
        var counter = 0;
        for (var item of this.myStudents) {
          if (!item.moved) {
            counter++;
            item.index = counter;
            list.push(item);
          }
        }
      }
      return list;
    },
  },
  created() {
    this.selectedSemester = this.$store.state.schoolCurrentSemester;
    if (!this.school) {
      this.school = this.userData.school;
    } else {
      this.school = this.zSchool;
    }
    if (this.userData.school && this.userData.school.hasLeaderboard) {
      this.disableLeaderboard = false;
    }

    console.log(this.school);
  },
  watch: {
    selectedSemester() {
      this._setupp();
    },
  },
  methods: {
    _getScores(eLesson, semester) {
      // return eLesson.allScores.map((xx) => xx.score).join(", ");
      return eLesson.allScores
        .filter((ss) => ss.semester == semester)
        .map((xx) => xx.score)
        .join(", ");
    },
    print(eLesson) {
      console.log(eLesson.ref.path);
    },
    _getAverage(lesson) {
      if (lesson.allScores && lesson.allScores.length > 0) {
        var xx =
          lesson.allScores
            .map((xx) => xx.score)
            .reduce((sum, num) => sum + num, 0) / lesson.allScores.length;
        return xx.toFixed(0);
      } else {
        return "-";
      }
    },
    _setupp() {
      this.loading = true;
      var classGroupRef =
        this.userData["classGroup-" + this.userData.school.currentYear];
      this.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("classGroupRefs", "array-contains", classGroupRef)
        .onSnapshot((docs) => {
          console.log(classGroupRef);
          this.eeljitLessons = [];
          var loadingCounter = 0;
          docs.forEach(async (doc) => {
            let eeljitLesson = doc.data();
            eeljitLesson.id = doc.id;
            eeljitLesson.ref = doc.ref;
            eeljitLesson.participated = false;

            if (!eeljitLesson.deleted) {
              if (
                eeljitLesson.esisLessonType &&
                eeljitLesson.esisLessonType.esisLessonTypeId != 1
              ) {
                var xzz = await eeljitLesson.ref
                  .collection("students-1")
                  .doc(this.userData.PERSON_ID)
                  .get();
                console.log(
                  eeljitLesson.ref.path,
                  eeljitLesson.courseInfo.COURSE_NAME,
                  xzz.exists
                );
                eeljitLesson.participated = xzz.exists;
              }
              if (
                eeljitLesson &&
                eeljitLesson.byTeachers &&
                eeljitLesson.byTeachers.length > 0
              ) {
                await this.userData.school.ref
                  .collection("unelgee1-" + this.$store.state.schoolStartYear)
                  .doc(String(eeljitLesson.courseInfo.COURSE_ID))
                  .collection(
                    "unelgee1-" + this.$store.state.schoolStartYear + "-scores"
                  )
                  .where("PERSON_ID", "==", String(this.userData["PERSON_ID"]))
                  .get()
                  .then((docs) => {
                    eeljitLesson.allScores = [];
                    docs.forEach(async (doc) => {
                      let score = doc.data();
                      score.ref = doc.ref;
                      score.id = doc.id;
                      eeljitLesson.allScores.push(score);
                    });
                  });
                this.$forceUpdate();

                var xx = this.eeljitLessons.find(
                  (ll) => ll.id == eeljitLesson.id
                );
                if (!xx) {
                  this.eeljitLessons.push(eeljitLesson);
                }
                loadingCounter++;
              } else {
                loadingCounter++;
              }
            } else {
              loadingCounter++;
            }

            if (loadingCounter == docs.size) {
              this.loading = false;
            }
          });
        });
    },
    _showLessonInfo(eLesson) {
      var xx = null;
      for (const tt of eLesson.byTeachers) {
        xx = xx ? xx + ", " + tt.teacherDisplayName : tt.teacherDisplayName;
      }
      this.$swal({
        title: eLesson.courseInfo.COURSE_NAME,
        text: "Багш: " + xx,
      });
    },

    _getScore(student, eLesson) {
      if (eLesson.allScores) {
        eLesson.allScores.map((xx) => xx.score).join(", ");
      } else {
        return [];
      }
    },

    _getShortenName(text) {
      const words = text.split(/\s+/);
      if (words.length == 1) {
        return words[0].substring(0, 3).toUpperCase();
      } else {
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase();
        });
        const capitalizedText = capitalizedWords.join("");
        return capitalizedText;
      }
    },

    _print(item) {
      console.log(item.ref.path);
    },

    _detail() {},
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 1px 4px;
  text-align: left;
  border: 1px solid #ddd;
  /* Change this to your desired border style */
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
  /* Change this to your desired background color */
}
</style>
